
const axios = require('axios')
const url = "https://goevent.genielabel.com";



const initialState = {



  count: 0,
 

  isLogin:false,
  user:{},
  url:"http://127.0.0.1:81",
  axios:axios,

  archives:[]




};

const appReducer = (state = initialState, action = {}  ) => {

  switch (action.type) {

    case "LOGIN":
    console.log("login state")

      return {
        ...state,
        nodata: action.data
      };

      case "CITY":


        return {
          ...state,
          city: action.data
        };


    default:
      return state
  }
}

export default appReducer
