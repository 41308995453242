import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import routes from './routes';

import { store, history } from './app/store';
import { Provider } from 'react-redux';
 
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router' // react-router v4/


import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Provider store={store()}>
    <ConnectedRouter history={history}>
    { routes }
    </ConnectedRouter>
  </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
