import React from 'react';


import PhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/style.css'
import Radio from '@material-ui/core/Radio';
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';


import { RadioGroup, RadioButton } from 'react-radio-buttons';
import axios from 'axios';



const GreenRadio = {
  root: {
    color: "grey",
    '&$checked': {
      color: "#b71c1c",
    },
  },
}

class App extends React.Component {
  constructor(params) {
      super(params)
      // initial gender state set from props
      this.state = {
        gender: "monthly",
        phone:"",
        step:1,
        two:false,
        product:{},
        isProduct:false,
        then:false,
        load:false,
        amount:"",
        url:"https://checkout.genielabel.com",
        value:2,
        user:{}
      }
      this.setGender = this.setGender.bind(this)
   }




   componentDidMount() {



     axios.get(`${this.state.url}/products${this.props.match.url}`)
             .then( response => {

               console.log(response.data.price);

               if (response.data.price.purchase) {
                 console.log("init product");
                 this.setState({gender: "purchase",isProduct:true,amount:response.data.price.purchase.formatted})

               }else {
                 this.setState({gender: "monthly",isProduct:false,amount:response.data.price.monthly.formatted})
               }
       this.setState({product: response.data,load:true});

             })
             .catch( error => {
               console.log(error);
               this.setState({then: false});
         //console.log(error);
             });
   }




   onChangetext = event => {
     const field = event.target.name;
     const user = this.state.user;
     user[field] = event.target.value;
     ////*.log(user);
     this.setState({
       user
     });


   };



  setGender(e) {
  this.setState({
    gender: e.target.value,
    amount:this.state.product.price[e.target.value].formatted
  })



}


//https://pay.liveroomplus.com/buy/subscription
handleChangePhone  = (e) => {

this.setState({
  value: e.target.value
})
  console.log(this.state.value);


}



link = (link) => {

window.location.replace(link);
}





pay = () => {
    if (this.state.user.email) {
var form = {plan:this.state.gender,product:this.state.product,name:this.state.user.name,email:this.state.user.email};
this.setState({
  then:true
})
axios.post(`${this.state.url}/prepare${this.props.match.url}`, form)
.then( response => {
  console.log(response.data);
  this.postForm(response.data.url)
 setTimeout(  () => {
   this.setState({
     then:false
   })
 }, 10000);

})
.catch( error => {
//*.log(error);
this.setState({
  then:false
})
});


}else {
  alert("Veuillez entrer votre email")
}

}




postForm(path, params, method) {
    method = method || 'post';

    var form = document.createElement('form');
    form.setAttribute('method', method);
    form.setAttribute('action', path);

    for (var key in params) {
        if (params.hasOwnProperty(key)) {
            var hiddenField = document.createElement('input');
            hiddenField.setAttribute('type', 'hidden');
            hiddenField.setAttribute('name', key);
            hiddenField.setAttribute('value', params[key]);

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
}








render() {
    const {gender} = this.state
  return (
        <>

  {!this.state.load &&
        <div   align="center" style={{marginTop: 60}}>
        <br/>  <br/>  <br/>
         <CircularProgress color='inherit' size={60} />
         </div>
       }


        {this.state.load &&
    <div className="Site">
    <nav class="navbar navbar-expand-lg navbar-light  "  >
        <div class="container">
        <a class="navbar-brand" href="https://www.liveroomplus.com">     <img src={"/logo.png"}  width="250" alt="logo" />
</a>

            <div class="collapse navbar-collapse" id="navbarResponsive">

            </div>
        </div>
    </nav>





    {!this.state.two &&
    <div class="container" style={{marginTop: 5}}>
      <div class="row justify-content-md-center">


      {this.state.step == 1 &&
        <div class="col col-lg-5">

        {/*
<button style={{backgroundColor: "transparent",border:0,borderRadius: 3}} onClick={() => this.setState({ two:true })}  >
        <i class="fa fa-arrow-circle-left" style={{color: "white",fontSize: 30}}></i>
         </button>
       */}

    <div align="center">
  {this.state.product.thumbnail && <img src={this.state.product.thumbnail.medium} class="img-fluid" width="400" /> }
  <br/>  <br/>
  <h1 class="h2" style={{color: 'white',}} >{this.state.product.name}</h1>
    <br/>
    </div>


















                                                  {!this.state.isProduct &&  <div align="center" style={{width: "100%",backgroundColor: "white",height: 60,borderRadius: 3,padding: 10,marginTop: 10}}>
                                                    <div class="row justify-content-md-center">
                                                  {this.state.product.price &&  <div class="col-8 ">
                                                        <h5 style={{marginTop: 11,marginLeft: -57,fontSize: 18,}}><span style={{fontWeight: 'bold',}}> </span>Mensuel / {this.state.product.price.monthly.formatted}</h5>
                                                    </div>
                                                  }

                                                      <div class="col col-lg-4">


                                                      <Radio
                                              checked={gender == "monthly"}
                                              onChange={this.setGender}
                                              value="monthly"
                                              name="radio-button-demo"
                                              inputProps={{ 'aria-label': 'A' }}
                                              />
                                                      </div>
                                                        </div>



                                                              </div>
                                                            }


                                                            {!this.state.isProduct &&  <div align="center" style={{width: "100%",backgroundColor: "white",height: 60,borderRadius: 3,padding: 10,marginTop: 10}}>
                                                              <div class="row justify-content-md-center">
                                                            {this.state.product.price &&  <div class="col-8 ">
                                                                  <h5 style={{marginTop: 11,marginLeft: -57,fontSize: 18,}}><span style={{fontWeight: 'bold',}}> </span>Annuel / {this.state.product.price.yearly.formatted}</h5>
                                                              </div>
                                                            }

                                                                <div class="col col-lg-4">


                                                                <Radio
                                                          checked={gender == "yearly"}
                                                          onChange={this.setGender}
                                                          value="yearly"
                                                          name="radio-button-demo"
                                                          inputProps={{ 'aria-label': 'A' }}
                                                          />
                                                                </div>
                                                                  </div>



                                                                        </div>
                                                                      }







                                              {this.state.isProduct &&  <div align="center" style={{width: "100%",backgroundColor: "white",height: 60,borderRadius: 3,padding: 10,marginTop: 10}}>
                                                <div class="row justify-content-md-center">
                                              {this.state.product.price.purchase &&  <div class="col-8 ">
                                                    <h5 style={{marginTop: 11,marginLeft: -57,fontSize: 18,}}><span style={{fontWeight: 'bold',}}> </span>{this.state.product.price.purchase.formatted}</h5>
                                                </div>
                                              }

                                                  <div class="col col-lg-4">


                                                  <Radio
                                          checked={gender == "purchase"}

                                          value="purchase"
                                          name="radio-button-demo"
                                          inputProps={{ 'aria-label': 'A' }}
                                          />
                                                  </div>
                                                    </div>



                                                          </div>
                                                        }




<br/>
                </div>

}

        <div class="col-sm-6" style={{padding: 15,marginTop: -40}}>


                <div style={{width: "100%",backgroundColor: "white",borderRadius: 3,padding: 10,marginTop: 10,textAlign: 'center',paddingTop: 60}}>


{this.state.step == 1 &&
  <div>
              <h4 style={{fontWeight: 'bold',}}>Créer un compte    </h4>

<div style={{padding: 30}}>


<div className="field">
      <input

onChange={this.onChangetext}
        type="text"
        onChange={this.onChangetext}

        name='name'
        placeholder="Nom complet"

      />
    </div>


          <div className="field">
                <input

        onChange={this.onChangetext}
                  type="text"
                  name='email'
                  placeholder="E-mail"

                />
              </div>







              <div   style={{marginLeft: "15%",width: "70%"}}>







                    </div>



                  <hr />




        <div  >


          <div class="space-header">
              <h6 style={{marginLeft: 7 }}><span style={{fontWeight: 'bold',}}> Total  </span>  </h6>



              <h6  style={{fontWeight: 'bold'}} ><span  >{this.state.amount}</span>  </h6>





          </div>
            </div>
<br/>
            <FormControlLabel
          control={<Checkbox checked={true}   name="gilad" />}
          label={<p style={{fontWeight:300,textAlign:"left",fontSize:13}}>J'accepte de recevoir des newsletters d'information et des mises à jour de produits de la part d'Ambigu.</p>}
        />

            {this.state.then &&
              <div   align="center" style={{marginTop: 60}}>
               <CircularProgress color="secondary" />
               </div>
             }
             <br/>
             {!this.state.then &&
                    <button   onClick={() =>this.pay()}  style={{backgroundColor: "#b71c1c",width: "100%" ,color: "#fff",height: 52,borderColor: "transparent",borderRadius: 3}}> Payez maintenant</button>
            }
<br/>
            <img src="payments.png"  class="img-fluid" />


            <div style={{fontSize: 13,color: "grey",fontWeight:300}}>
                  <p>
          En effectuant cet achat, vous nous autorisez à débiter votre carte bancaire,
           vous acceptez nos Conditions d'utilisation, notre politique concernant les cookies,
            & Politique de confidentialité, et vous déclarez avoir au moins 16 ans.
           Les montants prélevés sur votre carte apparaîtront sous l'intitulé OTT* EMBIGU.
                  </p>
                    </div>
    </div>



            </div>}





{this.state.step == 2 &&
  <div>

  <FormControl component="fieldset">
   <FormLabel component="legend">Mobile Money</FormLabel>
   <RadioGroup aria-label="gender" name="gender1" value={this.state.value} onChange={this.handleChangePhone}>
     <FormControlLabel value="1" control={<Radio />} label="Mpesa" />
     <FormControlLabel value="2" control={<Radio />} label="Orange Money" />
   <FormControlLabel value="3" control={<Radio />} label="Airtel Money" />
   </RadioGroup>
 </FormControl>

  <div   style={{marginLeft: "15%",width: "70%"}}>




  <TextField
    name="phone"
    onChange={this.onChangetext}
    value={this.state.user.phone}
    hintText="Numéro de téléphone en 10 chiffres"
    type="number"
    underlineShow={false}
    floatingLabelText="Numéro de téléphone"
    fullWidth={true}
  />
        </div>
{this.state.then &&
  <div   align="center" style={{marginTop: 60}}>
   <CircularProgress color="secondary" />
   </div>
 }
 {!this.state.then &&
        <button  onClick={this.pay}  style={{backgroundColor: "#b71c1c",width: "70%" ,color: "#fff",height: 52,borderColor: "transparent",borderRadius: 3,margin:20,marginTop: 60}}> Payez maintenant</button>
}
</div>
}

{this.state.step == 3 &&
  <div>


  <div style={{padding: 50}}>
        <p>
          <h4 style={{}}>Confirmez votre paiement

            </h4>
<i class="fa fa-check-circle" style={{fontSize: 50}}></i><br/>

        Votre requête est en cours de traitement, vous recevrez un message de confirmation . Cela peut prendre 60 secondes .         </p>
          </div>
</div>
}

</div>



          </div>
      </div>



    </div>}



















<br/>
<br/>
<br/>
    </div>
  }
    </>
  );
}

}

export default App;
